import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { merge } from 'lodash';
import * as moment from 'moment';
import { fromEvent, Observable, ReplaySubject, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { DvrControlsPlaybackState, DvrControlsState } from '../models/dvr.model';
import { BadgeEvent } from '@weavix/models/src/badges/event';
import { BadgeUpdate } from '@weavix/models/src/badges/badge-update';
import { Facility } from '../models/facility.model';
import { Item } from '../models/item.model';
import { Person } from '../models/person.model';
import { Topic } from '@weavix/models/src/topic/topic';
import { DvrItem, DvrItems, DvrPeople, DvrPerson, DvrState, Geofence, MapCacheObject } from '../models/weavix-map.model';
import { CSS_BREAKPOINTS } from '../utils/css-breakpoints';
import { hashLocation } from '../utils/polygon';
import { Utils } from '../utils/utils';
import { AccountService } from './account.service';
import { AlertService } from './alert.service';
import { BadgeService } from './badge.service';
import { FacilityService } from './facility.service';
import { ItemService } from './item.service';
import { PersonService } from './person.service';
import { PubSubService } from './pub-sub.service';
import { TranslationService } from './translation.service';

export interface MapItemEvent {
    [id: string]: Item;
}

export interface MapGeofenceEvent {
    [id: string]: Geofence;
}

export interface BadgeRegistrationEvent {
    badgeName?: string;
}

export interface BadgeRegistrationPayload extends BadgeRegistrationEvent {
    badgeName: string;
    deregistered: boolean;
}

export interface BadgePayload {
    personId?: string;
    event?: BadgeUpdate;
    registration?: BadgeRegistrationPayload;
}

export enum DvrEntities {
    People = 'people',
    Items = 'items',
}

export const MAP_UPDATE_INTERVAL: number = 10_000;

@Injectable({
    providedIn: 'root',
})
export class MapService {
    private readonly mapTranslationKeys: string[] = [
        'generics.edit',
        'generics.moreInfo',
        'name',
        'area',
        'company.company',
        'people',
        'items.items',
        'generics.wifi-routers',
        'beacons',
    ];
    private queryParamFilters: { [key: string]: string } = {};
    public dvrPlaybackState: DvrControlsPlaybackState = {
        inPlaybackMode: false,
        timestamp: null,
    };
    private dvrControlsState: DvrControlsState = {
        togglePlaybackMode: false,
        range: { from: moment().startOf('day').toDate(), to: moment().endOf('day').toDate() },
        timestamp: new Date().getTime(),
        inPlaybackMode: false,
        firstLoad: false,
    };
    public dvrDataState: DvrState;
    private dvrDataStatePromise: Promise<void>;
    public dvrSliderDrop$: Subject<any> = new Subject();
    public dvrPlaybackState$: Subject<DvrControlsPlaybackState> = new ReplaySubject(1);

    public dashboard: boolean = true;
    public dashboard$: ReplaySubject<boolean> = new ReplaySubject(1);
    private mapUpdateInterval: number;
    public mapUpdateIntervalTrigger: Subject<void> = new Subject();

    private subscribedToBadges;
    private prevWindowSize: number;
    public routeParamChange$: Subject<{ [key: string]: string }> = new Subject();

    private lastDvrTimestamp: number | undefined;

    constructor(
        public translationService: TranslationService,
        private facilityService: FacilityService,
        private peopleService: PersonService,
        private badgeService: BadgeService,
        private pubSubService: PubSubService,
        private accountService: AccountService,
        private alertService: AlertService,
        private route: ActivatedRoute,
        private itemService: ItemService,
    ) {
        if (this.route.queryParams) {
            this.route.queryParams.subscribe(params => this.setRouteFilters(params));
        }
    }

    startMapUpdateInterval(): void {
        if (!this.mapUpdateInterval) {
            this.mapUpdateInterval = window.setInterval(() => {
                this.mapUpdateIntervalTrigger.next();
            }, MAP_UPDATE_INTERVAL);
        }
    }

    stopMapUpdateInterval(): void {
        if (this.mapUpdateInterval && !this.mapUpdateIntervalTrigger.observers.length) clearInterval(this.mapUpdateInterval);
    }

    async subscribeBadgeUpdates(component: any) {
        const accountId = this.accountService.getAccountId();
        const facility = this.facilityService.getCurrentFacility();
        if (this.subscribedToBadges === (facility?.id ?? accountId)) return;
        this.subscribedToBadges = facility?.id ?? accountId;
        (await this.pubSubService.subscribe<BadgeEvent>(component, facility ? Topic.AccountFacilityPersonBadgeUpdated
            : Topic.AccountPersonBadgeUpdated, facility ? [accountId, facility.id, '+'] : [accountId, '+']))
            .subscribe((message) => {
                if (message.payload.location) {
                    if (this.dvrDataState) {
                        const time = new Date(message.payload.date).getTime();
                        if (time >= this.dvrDataState.range.from.getTime() && time < this.dvrDataState.range.to.getTime()) {
                            this.dvrDataState.events[message.replacements[1]] = this.dvrDataState.events[message.replacements[1]] || [];
                            this.dvrDataState.events[message.replacements[1]].push(message.payload as any);
                        }
    
                        const person = this.dvrDataState.people[message.replacements[1]];
                        if (person) {
                            person.latestEvent = message.payload;
                            if (!this.dvrPlaybackMode) person.badge = message.payload as any;
                        }
                    }
                }
            });
        (await this.pubSubService.subscribe<BadgeRegistrationEvent>(component, Topic.AccountPersonBadgeDeregistered, [accountId, '+']))
            .subscribe((message) => {
                if (this.dvrDataState) {
                    const person = this.dvrDataState.people[message.replacements[1]];
                    if (person && person.latestEvent) delete person.latestEvent.name;
                }
            });
    }

    set dashboardMode(value: boolean) {
        this.dashboard = value;
        this.dashboard$.next(value);
    }

    get dashboardMode() {
        return this.dashboard;
    }

    get dvrActiveTimeRange(): { from: number, to: number } {
        return { from: this.dvrControlsState.range.from.getTime(), to: this.dvrPlaybackMode ? this.dvrTimestamp : this.dvrControlsState.range.to.getTime() };
    }

    get dvrState() {
        return this.dvrControlsState;
    }

    get dvrTimestamp() {
        // dvr timestamp is used to compare with the current date. Default to current time if none set
        return (this.dvrPlaybackState && this.dvrPlaybackState.timestamp) || Date.now();
    }

    set dvrTimestamp(timestamp: number) {
        this.dvrPlaybackState.timestamp = timestamp;
    }

    get dvrPlaybackMode() {
        return this.dvrPlaybackState && this.dvrPlaybackState.inPlaybackMode;
    }

    set dvrPlaybackMode(inPlaybackMode: boolean) {
        this.dvrPlaybackState.inPlaybackMode = inPlaybackMode;
    }

    public clearPlaybackState(): void {
        this.dvrPlaybackState = null;
    }


    async initDvrRange(component: any, from: Date, to: Date, timestamp: number, entities: DvrEntities[], facilityId?: string) {
        from = new Date(timestamp - 90 * 60000);
        to = new Date(timestamp + 30 * 60000);
        const accountId = this.accountService.getAccountId();

        const locationTimeoutHrs = this.dvrDataState?.facility?.settings?.locationTimeoutHrs ?? 1.25;
        const minFrom = new Date(timestamp - locationTimeoutHrs * 3600_000);
        const maxTo = new Date(timestamp + 60_000);
        this.lastDvrTimestamp = undefined;

        if (this.dvrDataState?.range?.from?.getTime() <= minFrom.getTime()
            && this.dvrDataState?.range?.to?.getTime() >= maxTo.getTime()
            && this.dvrDataState?.facility?.id === facilityId
            && this.dvrDataState?.accountId === accountId) {
            await this.dvrDataStatePromise;
            return this;
        }

        let resolve;
        // eslint-disable-next-line promise/param-names
        this.dvrDataStatePromise = new Promise(r => resolve = r);
        this.alertService.withLoading(this.dvrDataStatePromise);
        this.dvrDataState = { people: {}, events: {}, facility: null, range: { from, to }, accountId };
        if (facilityId) this.dvrDataState.facility = await this.facilityService.get(component, facilityId, true);

        const getDvrPeople = async () => {
            this.dvrDataState.people = (await this.peopleService.getPeople(component)).reduce((obj: DvrPeople, p: DvrPerson) => (obj[p.id] = p, obj), {});
            const peopleEvents = await this.badgeService.getPeopleEvents(component, facilityId, from, to);
            const dvrEventsMap = peopleEvents.reduce(
                (acc, curr) => {
                    if (curr.personId) {
                        curr.facilities = [{id: facilityId}];
                        if (curr.personId in acc) acc[curr.personId].push(curr);
                        else acc[curr.personId] = [curr];
                    }
                    return acc;
                },
                {});
            this.dvrDataState.events = merge(dvrEventsMap, this.dvrDataState.events);
        };

        const getDvrItems = async () => {
            this.dvrDataState.items = (await this.itemService.getItems(component, facilityId)).reduce((obj: DvrItems, item: DvrItem) => (obj[item.id] = item, obj), {});
            const itemEvents = await this.badgeService.getAllItemEvents(component, facilityId, from, to);
            const dvrEventsMap = itemEvents.reduce(
                (acc, curr) => {
                    if (curr.itemId && curr.location) {
                        if (curr.itemId in acc) acc[curr.itemId].push(curr);
                        else acc[curr.itemId] = [curr];
                    }
                    return acc;
                },
                {});
            this.dvrDataState.events = merge(dvrEventsMap, this.dvrDataState.events);
        };

        for (const e of entities) {
            switch (e) {
                case DvrEntities.Items :
                    await getDvrItems();
                    break;
                case DvrEntities.People :
                    await getDvrPeople();
                    break;
            }
        }

        resolve();
        return this;
    }

    getDvrMinuteDataPeople(timestamp: number): { [key: string]: DvrPerson } {
        if (!timestamp) {
            if (this.dvrDataState) {
                Object.values(this.dvrDataState.people).forEach(p => p.badge = p.latestEvent || p.badge);
                const data = this.dvrDataState.people;
                return data;
            }
            return null;
        }

        const locationTimeoutHrs = this.dvrDataState?.facility?.settings?.locationTimeoutHrs ?? 1.25;
        const date = this.lastDvrTimestamp ?? (timestamp - locationTimeoutHrs * 3600_000);
        Object.values(this.dvrDataState.people).forEach(p => {
            const events = this.dvrDataState.events[p.id] || [];
            let min = 0;
            let max = events.length;
            while (min < max - 1) {
                const mid = Math.floor((min + max) / 2);
                if (new Date(events[mid].date).getTime() < date) {
                    min = mid + 1;
                } else {
                    max = mid;
                }
            }
            let latestEvent = this.lastDvrTimestamp ? p.badge : null;
            for (let i = min; i < events.length; i++) {
                const event = events[i];
                if (new Date(event.date).getTime() > timestamp) break;

                if (!latestEvent) latestEvent = {};
                Object.assign(latestEvent, events[i]);
            }

            p.latestEvent = p.latestEvent || p.badge;
            if (!latestEvent?.location) latestEvent = null;
            if (latestEvent) latestEvent.name = p.badge && p.badge.name || 'yes';
            p.badge = latestEvent;
        });

        this.lastDvrTimestamp = timestamp;
        return this.dvrDataState.people;
    }

    getDvrMinuteDataItems(timestamp: number): { [key: string]: DvrItem } {
        if (!timestamp) {
            if (this.dvrDataState) {
                return this.dvrDataState.items;
            }
            return null;
        }

        Object.values(this.dvrDataState.items).forEach(item => {
            const events = this.dvrDataState.events[item.id] || [];
            let min = 0;
            let max = events.length;
            while (min < max - 1) {
                const mid = Math.floor((min + max) / 2);
                if (new Date(events[mid].date).getTime() < timestamp) {
                    min = mid;
                } else {
                    max = mid;
                }
            }
            item.latestEvent = Object.assign({}, events[min]);
        });

        return this.dvrDataState.items;
    }

    public setDvrPlaybackState(playbackState: DvrControlsPlaybackState): void {
        this.dvrPlaybackState = playbackState;
        this.dvrPlaybackState$.next(playbackState);
    }

    public setDvrControlsState(controlsState: DvrControlsState) {
        this.dvrControlsState = controlsState;
    }

    getMapTranslations(): Observable<{ [key: string]: string }> & PromiseLike<{ [key: string]: string }> {
        return Utils.awaitable(this.translationService.getTranslations(this.mapTranslationKeys));
    }

    public setRouteFilters(queryParams: any): void {
        this.queryParamFilters = {};
        for (const param in queryParams) {
            if (queryParams[param]) {
                this.queryParamFilters[param] = queryParams[param].toString();
            }
        }

        this.routeParamChange$.next(this.queryParamFilters);
    }

    public getRouteFilters(): { [key: string]: string } {
        return this.queryParamFilters;
    }

    public getRouteFilter(param: string): string {
        return this.queryParamFilters?.[param];
    }

    public setupDashboardDisplay(component): void {
        if (window.innerWidth < CSS_BREAKPOINTS.full) this.dashboardMode = false;
        this.prevWindowSize = window.innerWidth;

        Utils.safeSubscribe(component, fromEvent(window, 'resize')).pipe(debounceTime(250)).subscribe(x => {
            const resizingDown: boolean = this.prevWindowSize > CSS_BREAKPOINTS.full && window.innerWidth < CSS_BREAKPOINTS.full;
            const resizingUp: boolean = this.prevWindowSize < CSS_BREAKPOINTS.full && window.innerWidth > CSS_BREAKPOINTS.full;

            if (resizingDown) {
                this.dashboardMode = false;
            } else if (resizingUp) {
                this.dashboardMode = true;
            }
            this.prevWindowSize = window.innerWidth;
        });
    }

    public async updateHeatMapData(component, cache: MapCacheObject, facility: Facility, filterPoint, people: Map<string, Person>): Promise<{ cache: MapCacheObject, locations: {[key: string]: any[]} }> {
        if (!cache) return;
        const points = cache.heatmap;
        const addPoint = function (point: number[], weight: number) {
            if (weight <= 0 || isNaN(weight)) return;
            const hash = hashLocation(point);
            if (!points[hash.hash]) points[hash.hash] = { location: new google.maps.LatLng(hash.location[1], hash.location[0]), weight };
            else points[hash.hash].weight += weight;
        };
        const startTime: moment.Moment = moment().tz(facility.timezone).startOf('day');
        const endTime: moment.Moment = moment().tz(facility.timezone).endOf('day');
        const filters = cache.filters;
        const locations = {};
        // const filterPoint = ;
        if (!this.dvrDataState || this.dvrDataState.facility?.id !== facility?.id) await this.initDvrRange(component, startTime.toDate(), endTime.toDate(), this.dvrTimestamp, [DvrEntities.People], facility?.id);

        const from = this.dvrDataState.range.from.getTime();
        const to = Math.max(this.dvrTimestamp, this.dvrDataState.range.to.getTime());

        [...people?.keys() || []].forEach(id => {
            const locationEvents = this.dvrDataState.events[id] ?? [];
            let lastEvent;
            let lastEventIndex = cache.points[id] ?? 0;
            for (let i = lastEventIndex; i < locationEvents.length; i++) {
                const event = locationEvents[i];
                if (!event.location) continue;

                if (lastEvent) {
                    const start = new Date(lastEvent.date).getTime();
                    const end = new Date(event.date).getTime();
                    if (end > from && start < to) {
                        const personId = lastEvent.personId;
                        filters[personId] = 1;
                        if (!locations[personId]) locations[personId] = [];
                        locations[personId].push(lastEvent);
                        if (filterPoint(personId, lastEvent.location)) {
                            const duration = Math.min(end, to) - Math.max(start, from);
                            if (duration > 0) addPoint(lastEvent.location, duration / 1000);
                        }
                    }
                }
                lastEventIndex = i;
                lastEvent = event;
            }
            cache.points[id] = lastEventIndex;
        });

        return { cache, locations };
    }
}
