import { CDK_DRAG_CONFIG, CdkDragDrop, CdkDragMove, DragDropModule } from '@angular/cdk/drag-drop';
import { CdkVirtualScrollViewport, ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule, Location } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    DoCheck,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    Renderer2,
    SimpleChanges,
    TrackByFunction,
    ViewChild,
    forwardRef,
} from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { AnalyticsService, StAction, StObject } from 'weavix-shared/services/analytics.service';
import { Modal, ModalActionType, ModalService, ModalType } from 'weavix-shared/services/modal.service';
import { ThemeService } from 'weavix-shared/services/themeService';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { Folder, FolderAccessResponse, SystemFolderType } from '@weavix/models/src/folder/folder';
import { FormAction } from 'weavix-shared/models/forms.model';
import {
    BulkEdit,
    BulkEditAction,
    BulkEditEvent,
    FolderChange,
    PaginationDirection,
    Paginator,
    RowEdit,
    RowEditAction,
    RowItem,
    RowItemType,
    TableColumn,
    TableColumnGroup,
    TableEdit,
    TableExportData,
    TableOptions,
    TableRow,
    TableSetting,
    commonRowEdits,
} from 'weavix-shared/models/table.model';
import { PermissionAction } from 'weavix-shared/permissions/permissions.model';
import { AlertService } from 'weavix-shared/services/alert.service';
import { BatchMethod, BatchRequest, BatchService } from 'weavix-shared/services/batch.service';
import { FolderService } from 'weavix-shared/services/folder.service';
import { ProfileService } from 'weavix-shared/services/profile.service';
import { TranslationService } from 'weavix-shared/services/translation.service';
import { css } from 'weavix-shared/utils/css';
import { FEATURE_ICONS } from 'weavix-shared/utils/feature.icons';
import { sleep } from 'weavix-shared/utils/sleep';
import { AutoUnsubscribe, Utils } from 'weavix-shared/utils/utils';
import { TableService } from './table.service';
import { environment } from 'environments/environment';
import { debounceTime } from 'rxjs/operators';
import { HttpError } from '@weavix/models/src/api/http-response';
import { TableHeaderComponent } from './table-header/table-header.component';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { LogoSpinnerComponent } from 'components/logo-spinner/logo-spinner.component';
import { ModalComponent } from 'components/modal/modal.component';
import { TableFilterComponent } from './table-filter/table-filter.component';
import { ParentEditFormComponent } from './parent-edit-form/parent-edit-form.component';
import { TableRowComponent } from './table-row/table-row.component';
import { FolderEditorModalComponent } from './folder-editor-modal/folder-editor-modal.component';
import { IconComponent } from 'components/icon/icon.component';

export const ROOT_FOLDER = 'root-folder';
const SYSTEM_FOLDER_TYPES: string[] = Object.values(SystemFolderType);
const DEFAULT_ROW_ITEM_HEIGHT: number = 40;
const MAX_SUB_TABLE_HEIGHT: number = 300;

const DRAG_CONFIG = {
    previewContainer: 'parent',
    dragStartThreshold: 0,
    pointerDirectionChangeThreshold: 0,
};

@AutoUnsubscribe()
@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss', './table-teams.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: CDK_DRAG_CONFIG, useValue: DRAG_CONFIG }],
    standalone: true,
    imports: [
        CommonModule,
        DragDropModule,
        MatCheckboxModule,
        MatTooltipModule,
        RouterModule,
        ScrollingModule,
        TranslateModule,

        FolderEditorModalComponent,
        IconComponent,
        LogoSpinnerComponent,
        ParentEditFormComponent,
        ModalComponent,
        TableFilterComponent,
        TableHeaderComponent,
        forwardRef(() => TableRowComponent),
    ],
})
export class TableComponent implements OnChanges, DoCheck, OnInit {
    private readonly LAZY_LOAD_REMAINING_SCROLL_DISTANCE: number = 0;

    constructor(
        private cdr: ChangeDetectorRef,
        private elRef: ElementRef,
        private renderer: Renderer2,
        private translationService: TranslationService,
        private profileService: ProfileService,
        private route: ActivatedRoute,
        private location: Location,
        public tableService: TableService,
        private modalService: ModalService,
        private folderService: FolderService,
        private alertsService: AlertService,
        private batchService: BatchService,
    ) {
        if (this.route.queryParams) Utils.safeSubscribe(this, this.route.queryParams).subscribe(params => this.currRouteParams = params);
    }

    @Input() options: TableOptions;
    @Input() rows: any[] = [];
    @Input() folders: FolderAccessResponse[];
    @Input() totalItems: number = 0;
    @Input() isLazyLoading = false;
    @Input() folderEditorOpen: boolean = false;
    @Input() externalSearchInput: string;
    /**
     * The Focused row will be highlighted with a different shade of gray
     * @param focusedRow The ID of the row to highlight (via keyCol)
     */
    @Input() focusedRow: string = null;
    @Output() columnClickOutput = new EventEmitter<{row: TableRow, item: RowItem}>();
    @Output() colPrefixClickOutput = new EventEmitter<{row: TableRow, item: RowItem}>();
    @Output() colPostfixClickOutput = new EventEmitter<{row: TableRow, item: RowItem}>();
    @Output() colEditClickOutput = new EventEmitter<{row: TableRow, edit: RowEdit}>();
    @Output() rowClickOutput = new EventEmitter<TableRow>();
    @Output() rowEditOutput = new EventEmitter<{key: string, action: RowEditAction}>();
    @Output() rowMovedOutput = new EventEmitter<{key: string, folderId: string, success: (key: string) => void}>();
    @Output() bulkEditOutput = new EventEmitter<BulkEditEvent>();
    @Output() folderEditorModalOutput = new EventEmitter<ModalActionType>();
    @Output() selectedRowsOutput = new EventEmitter<{ [key: string]: TableRow; }>();
    @Output() folderIdOutput = new EventEmitter<string>();
    @Output() exportReportOutput = new EventEmitter<TableExportData>();
    @Output() lazyLoadMoreItemsOutput = new EventEmitter<void>();
    @Output() tableEditClickOutput = new EventEmitter<{edit: TableEdit}>();

    teamsApp = environment.teamsApp;
    currRouteParams: {[key: string]: string};

    private dragPosition = { x: 0, y: 0 };
    dragDisabled: boolean = false;
    @ViewChild('dragContainer') dragContainer: ElementRef;

    // --- Template variables ---
    allRows: TableRow[] = []; // all rows transformed to TableRows
    curRows: TableRow[] = []; // subset of allRows, filtered
    slicedRows: TableRow[] = []; // subset of curRows, currently shown to user
    folderIds: string[] = [];
    folderPath: Folder[] = [];
    totalRows: number;
    maxEdits: RowEdit[] = [];

    pageRows: TableRow[] = [];
    headers: TableColumn[] = [];

    // Pagination
    pageStart: number = 0;
    initPageSize: number = 1000;
    showMoreSize: number = 500;
    pageEnd: number = 1;
    curPage: number = 0;
    curDirection: PaginationDirection = PaginationDirection.asc;

    selectedFolderId: string;
    folderMap: {[key: string]: Folder} = {};
    folderItemsCountMap: {[key: string]: number} = {};
    folderId: string;

    // Search
    searchQuery: string = '';
    searchFields: string[] = [];

    selectedFolderKeys: {[key: string]: TableRow} = {};
    selectedKeys: {[key: string]: TableRow} = {};
    selectedNestedKeys: {[key: string]: TableRow} = {};
    objectKeys = Object.keys;
    showCheckboxes: boolean = true;
    multiSelect: boolean = true;
    selectable: boolean = true;
    rowsClickable: boolean = true;
    rowsDblClickable: boolean = false;
    checkOnClick: boolean = false;
    hightlightOnClick: boolean = false;
    colKey: {[key: string]: TableColumn} = {};
    rowItemType = RowItemType;
    optionsLoaded: boolean = false;
    focusedRowDetector: string = this.focusedRow;

    tableEdits: TableEdit[] = [];
    bulkEdits: BulkEdit[] = [];
    inTableThatIsMoveable = true;
    someRowsEditable: boolean = false;

    // Tags and filter
    showTagFilter: boolean = false;
    selectedTagIds: string[] = [];
    activeTags: boolean = false;
    showCraftFilter: boolean = false;
    selectedCraftIds: string[] = [];
    activeCrafts: boolean = false;
    activeFilter: boolean = false;
    @ViewChild('tableBody') tableBody: CdkVirtualScrollViewport;
    @ViewChild('tableHeader') tableHeader: ElementRef;
    private readonly BACK_TO_TOP_DISTANCE: number = 1000;
    lazyloadItems$ = new Subject<Event>();
    showBackToTop: boolean = false;
    // --- END Template variables ---
    loadingMore: boolean = false;

    private isRowDblClick: boolean = true;
    allItems: any[];
    subTableHeight: number;

    lightTheme: boolean;

    folderChanges: EventEmitter<FolderChange> = new EventEmitter();
    activeRow: TableRow;
    currentFolderId: string;

    modalInput: Modal;
    activeModalType: ModalType;
    modalType = ModalType;
    circularFolderIds: string[] = [];
    selectableFolderIds: string[] = [];
    totalsRow: TableRow;
    get noMoreItems(): boolean { return this.pageEnd === this.curRows.length - 1; }

    wheelEvent$: Subject<WheelEvent | null> = new Subject();

    private debouncedEmitRows = _.debounce((emitNull = false) => this.emitRows(emitNull), 100);

    get staticTableEdits(): TableEdit[] { return this.options.tableEdits?.filter(t => t.static === true); }
    get dynamicTableEdits(): TableEdit[] { return this.options.tableEdits?.filter(t => !t.static); }

    ngOnInit() {
        this.lightTheme = ThemeService.getLightTheme();
        if (this.options.pagination) {
            if (this.options.pagination.initPageSize) {
                this.initPageSize = this.options.pagination.initPageSize;
                this.pageEnd = this.initPageSize - 1;
            }
            if (this.options.pagination.showMoreSize) {
                this.showMoreSize = this.options.pagination.showMoreSize;
            }
        }

        if (this.options.paginate) {
            if (this.options.paginate.pageSize) {
                this.initPageSize = this.options.paginate.pageSize;
            }

            if (this.options.paginate?.isLazyLoaded) {
                this.lazyloadItems$
                    .pipe(debounceTime(500))
                    .subscribe(event => this.checkLazyLoading(event));
            }
        }

        if (this.options.isSubTable) {
            this.setSubTableHeight();
        }

        // prevent browser default click and drag behavior
        document.addEventListener('dragstart', (event) => event.preventDefault());

        this.wheelEvent$.subscribe(event => this.handleHorizontalScroll(event));
        this.tableService.loading$.subscribe(loading => {
            if (!loading) setTimeout(() => this.repositionColumnGroupHeaders());
        });
        Utils.safeSubscribe(this, this.tableService.filterUpdate$).subscribe(() => {
            this.rowsUpdated();
        });
    }

    emitRows(emitNull = false) {
        // include all keys and nested keys
        const nestedValues: {[key: string]: TableRow} = {};
        Object.values(this.selectedNestedKeys).forEach(row => {
            if (!row.isFolder) {
                nestedValues[row.key] = row;
            }
        });
        const emitting = {...this.selectedKeys, ...nestedValues};
        this.selectedRowsOutput.emit(emitNull ? null : emitting);
    }

    checkLazyLoading(event: Event) {
        const el = <HTMLElement> event.target;
        const remainingScroll = el.scrollHeight - (el.scrollTop + el.offsetHeight);
        if (remainingScroll <= this.LAZY_LOAD_REMAINING_SCROLL_DISTANCE) {
            this.lazyLoadMoreItemsOutput.emit();
        }
    }

    async ngOnChanges(event: SimpleChanges) {
        if (event.options) {
            await this.setTableOptions();
        }

        if (event.totalItems) {
            this.options.pagination?.pageUpdated?.call(this, this.totalRows, this.totalItems ?? 0);
        }

        if (event.totalItems && event.totalItems.currentValue === 0) {
            this.allRows = [];
            this.rows = [];
            this.curRows = [];
        }

        if (event.rows && (event.rows.firstChange || (event.rows.previousValue !== undefined && !_.isEqual(event.rows.currentValue, event.rows.previousValue) && this.optionsLoaded))) {
            this.rowsUpdated();
            this.pageEnd = this.initPageSize - 1;
        }

        if (event.externalSearchInput?.currentValue !== event.externalSearchInput?.previousValue) {
            this.setExternalSearch(event.externalSearchInput?.currentValue);
        }

        if (this.focusedRow !== this.focusedRowDetector) {
            this.focusedRowDetector = this.focusedRow;
            this.rowsUpdated();
        }

        // sleeping to allow data to render to get tableBody Element
        // If scrollbar isn't active, attempt to load more until it is or all is loaded.
        await sleep(0);
        this.renderEnoughForScroll();
    }

    async renderEnoughForScroll() {
        const scrollPort = this.tableBody?.elementRef?.nativeElement;
        if (scrollPort) {
            while (scrollPort.scrollHeight <= scrollPort.clientHeight && this.pageEnd !== this.curRows.length -1 && this.curRows.length > 0) {
                if (!this.loadingMore) this.showMore(false);
                await sleep(500);
            }
        }
    }

    async ngDoCheck() {
        if (this.rows && this.rows.length) {
            if (this.rows.length + (this.folders || []).length !== this.allRows.length && this.optionsLoaded) {
                this.rowsUpdated();
            }
        }
        this.wheelEvent$.next(null);
    }
    
    @HostListener('window:resize', ['$event'])
    onResize() {
        this.renderEnoughForScroll();
    }

    private setSubTableHeight(): void {
        const heightByRows: number = (this.rows?.[0]?.roHeight ?? DEFAULT_ROW_ITEM_HEIGHT) * this.rows.length;
        this.subTableHeight = Math.min(heightByRows, MAX_SUB_TABLE_HEIGHT) + 70; // 70 accounts for some vertical padding in table
    }

    // Called through ViewChild
    clearSelected() {
        this.allRows.forEach(r => r.selected = false);
        this.curRows.forEach(r => r.selected = false);
        this.rows.forEach(r => r.selected = false);
        this.slicedRows.forEach(r => r.selected = false);
        this.pageRows.forEach(r => r.selected = false);
        this.selectedKeys = {};
        this.debouncedEmitRows(true);
        this.selectedFolderKeys = {};
        this.selectedNestedKeys = {};
        this.cdr.markForCheck();
    }

    /**
     * Force a single row to update. Rebuilds the specific TableRow object.
     * @param key The row (entity) id. Can be a string or composite key. Also supports an array of keys
     */
    forceRowUpdate(key: string | object[]) {
        const keys: (object | string)[] = _.isArray(key) ? (key as object[]) : ([key as string]);

        keys.forEach((k, index) => {
            const rowsWithKey = this.rows.find(x => _.isEqual(x[this.options.keyCol], k));
            if (rowsWithKey) {
                const newTableRow = this.createTableRow(rowsWithKey);
                this.allRows[this.allRows.findIndex(x => _.isEqual(x.key, k))] = newTableRow;
                this.applyFiltersAndSearch(false, false);
                if (newTableRow.selected) this.selectedKeys[k as string] = newTableRow;
            }
        });
    }

    onSortClick(header: TableColumn) {
        this.options.columns.forEach(head => head.sort.selected = false);
        header.sort.selected = true;
        header.sort.sortAsc = !header.sort.sortAsc;

        if (this.options.settingsKey) {
            localStorage.setItem(`${this.options.settingsKey}-sort`, JSON.stringify({ [header.colKey]: header.sort.sortAsc }));
        }

        this.applyFiltersAndSearch(true, false, true);
    }

    hasPageRows() {
        return this.pageRows && this.pageRows.length > 0;
    }

    hasCurRows() {
        return this.curRows && this.curRows.length > 0;
    }

    hasAnyRows() {
        return this.allRows && this.allRows.length > 0;
    }

    hasRows() {
        return this.rows && this.rows.length > 0;
    }

    getNoDataMessage() {
        const messageKey = this.options?.noData?.messageKey;

        if (messageKey)
            return this.translationService.getImmediate(messageKey);
        else if (this.options.noData?.canAdd)
            return `${this.translationService.getImmediate('add')} ${this.translationService.getImmediate(this.options.title?.string)}`;
        else
            return this.translationService.getImmediate('no-data');
    }

    getSelectedKeyCount() {
        return Object.keys(this.selectedKeys).length + Object.keys(this.selectedFolderKeys).length;
    }

    private prepareDefaultSort() {
        if (this.options.settingsKey) {
            try {
                const sort = JSON.parse(localStorage.getItem(`${this.options.settingsKey}-sort`) || '{}');
                let empty = true;
                Object.keys(sort).forEach(x => {
                    const found = this.options.columns.find(c => c.colKey === x);
                    if (found) {
                        if (empty) {
                            this.options.columns.forEach(c => c.sort = { selected: false, sortable: true });
                        }
                        empty = false;
                        found.sort = {
                            selected: true,
                            sortable: true,
                            sortAsc: sort[x],
                        };
                    }
                });
                if (!empty) return;
            } catch (e) {
                console.error(e);
            }
        }
        const alreadySorted = this.options.columns.filter(c => c.sort && c.sort.sortable && c.sort.selected);
        if (alreadySorted && alreadySorted.length > 0) return;

        // check if there is a time-ago column to use that first
        // otherwise the first column
        const col = this.options.columns.find(c => c.type === RowItemType.timeAgo) || this.options.columns[0];
        if (col) {
            col.sort = {
                selected: true,
                sortable: true,
                sortAsc: col.type !== RowItemType.timeAgo,
            };
        }
    }

    private setRows() {
        if (!this.rows) {
            this.selectedKeys = {};
            this.selectedFolderKeys = {};
            this.selectedNestedKeys = {};
            this.debouncedEmitRows(true);
            return;
        }

        this.maxEdits = [];

        this.allRows = (this.folders || []).map(r => this.createFolderRow(r)).concat(this.rows.map(r => this.createTableRow(r)));
        this.setSelectedKeys();

        this.someRowsEditable = this.allRows.some(row => row.disabledRowText === '' || row.disabledRowText === null);

        if (this.options.totalsRowConfig?.show) this.setupTotalsRow();
    }

    // takes into account users with "deleted" folderIds
    private isInCurrentFolder(row: TableRow): boolean {
        const folderId = row.original.folderId;
        const folderIdExists = !folderId || (this.folders ?? []).some(x => x.id === folderId);
        if (!folderIdExists && !this.folderId) return true;
        return folderId === (this.folderId ?? undefined);
    }

    private canBeSelected(row: TableRow): boolean {
        const folderId = row.original.folderId;
        if (!this.folderId) return true;
        const allowedFolders = [this.folderId, ...this.getChildrenFolders(this.folderId)];
        if (allowedFolders.includes(folderId)) return true;
    }

    // handles filter changes
    private setSelectedKeys() {
        this.selectedKeys = _.keyBy(this.allRows.filter(r =>
            !r.isFolder
            && r.selected
            && this.isInCurrentFolder(r),
        ), r => r.key);
        this.selectedNestedKeys = _.keyBy(this.allRows.filter(r => 
            !r.isFolder
            && r.selected
            && !this.isInCurrentFolder(r)
            && this.canBeSelected(r),
        ), r => r.key);
        this.allRows.filter(x => x.isFolder && this.selectedFolderKeys[x.key]).forEach(folder => folder.selected = true);
        Object.keys(this.selectedFolderKeys).forEach(key => this.toggleNestedKeysSelection({ folderId: key, select: true }));
    }

    private setupTotalsRow(): void {
        const row = {};

        const rowItems = this.options.columns.map(c => ({
            colKey: c.colKey,
            type: c.type,
            value: c.total ? c.total(row) : null,
            minWidth: c.minWidth,
            maxWidth: c.maxWidth,
            prefix: (c.total && c.prefix) ? c.prefix(row) : null,
            rowItemNgStyleCb: c.rowItemNgStyleCb,
            rowItemNgStyle: c.rowItemNgStyle,
        }));

        this.totalsRow = {
            key: 'totals',
            items: rowItems,
            original: {},
            edits: [],
            clickable: false,
            isFolder: false,
            backgroundColor: css.colors.GRAY_LT,
        };
    }

    private createFolderRow(r: Folder): TableRow {
        const colKey = r.id === ROOT_FOLDER ? 'folderBack' : 'folderName';
        const showCheckbox = this.options.select?.showCheckboxesWithEditPermission || this.showCheckboxes;
        const lockCheckbox = (this.options.select?.showCheckboxesWithEditPermission ? !this.hasEditPermission(true, r) : false)
            || SYSTEM_FOLDER_TYPES.includes(r.id);
        const row: TableRow = {
            key: r.id,
            items: [{
                colKey,
                value: r.name,
            }],
            rowSuffix: this.options?.folderRowSuffix?.show && r.id !== ROOT_FOLDER ? {
                icon: () => this.options.folderRowSuffix.icon ?? FEATURE_ICONS[this.options.folderType]?.icon ?? FEATURE_ICONS.people.icon,
                text: (row) => `${this.folderItemsCountMap[row.key] ?? 0}`,
            } : null,
            original: r,
            selected: this.isFolderSelected(r[this.options.keyCol]),
            clickable: true,
            edits: r.id === ROOT_FOLDER || SYSTEM_FOLDER_TYPES.includes(r.id) ? [] :
                !(this.options?.headerOptions?.bulkEdits ?? []).some(e => e.action === BulkEditAction.delete) ?
                [commonRowEdits.edit, commonRowEdits.delete] : [commonRowEdits.edit]
             .map(edit => {
                if (!this.hasEditPermission(true, r)) {
                    return null;
                }
                return edit;
            }).filter(x => x),
            isFolder: true,
            link: `/${this.route.snapshot.pathFromRoot.map(x => x.url.join('/')).join('/')}`,
            checkbox: showCheckbox && r.id !== ROOT_FOLDER && !lockCheckbox,
            locked: showCheckbox && r.id !== ROOT_FOLDER && lockCheckbox,
            icon: { faIcon: `fas ${ r.id === ROOT_FOLDER ? 'fa-chevron-left' : 'fa-folder' }` },
            class: r.id === ROOT_FOLDER ? 'folder-back' : '',
        };

        if (row.edits.length > this.maxEdits.length) this.maxEdits = row.edits;
        return row;
    }

    private isFolderSelected(key: string): boolean {
        return !!this.selectedFolderKeys[key];
    }

    private createTableRow(r: any, options: TableOptions = this.options): TableRow {
        const rItems: RowItem[] = [];
        options.columns.forEach((c) => {
            const itemValue = c.value ? c.value(r, c) : _.get(r, c.colKey);

            rItems.push({
                colKey: c.colKey,
                value: itemValue,
                tooltip: c.tooltip ? c.tooltip(r) : null,
                sort: c.sort?.fn ? c.sort.fn(r) : null,
                minWidth: c.minWidth,
                maxWidth: c.maxWidth,
                maxLines: c.maxLines,
                lineExpand: c.lineExpand,
                class: c.class ? c.class(r) : '',
                pending: c.pending ? c.pending(r) : null,
                prefix: c.prefix ? c.prefix(r) : null,
                postfix: c.postfix ? c.postfix(r) : null,
                clickable: c.clickable ? c.clickable(r) : false,
                rowItemNgStyleCb: c.rowItemNgStyleCb,
                rowItemNgStyle: c.rowItemNgStyle,
            });
        });

        let locked = options.locked ? options.locked(r) : false;

        const rEdits: RowEdit[] = _.orderBy(_.cloneDeep(options.rowEdits)
            .map(edit => {
                edit.invisible = (!locked && edit.show && !edit.show(r) || !this.hasEditPermission(false, r));
                edit.disabled = typeof edit.disabled === 'function' ? edit.disabled(r) : edit.disabled;
                return edit;
            }).filter(x => x), x => !x.invisible);

        const showCheckbox = this.isCheckboxVisible(r);
        locked = this.updateLockedStatus(r, locked);
            
        const row: TableRow = {
            key: r[options.keyCol],
            items: rItems,
            original: r,
            selected: r.selected || this.isRowSelected(r[options.keyCol]),
            highlighted: this.focusedRow === r[options.keyCol],
            marked: this.options?.highlightedRows?.includes(r[options.keyCol]),
            disabledRowText: options.unavailableRowText ? options.unavailableRowText(r) : null,
            rowPrefix: !!this.options?.highlightedRows,
            edits: rEdits,
            clickable: this.rowsClickable,
            link: options.routerLink ? options.routerLink(r) : null,
            checkbox: showCheckbox && !locked,
            locked: showCheckbox && locked,
            subTable: r.subTable
                ? {
                    ...r.subTable,
                    rows: r.subTable.rows,
                } : null,
            icon: options.rowIcon && options.rowIcon(r),
            iconFixedWidth: options.rowIconFixedWidth,
            backgroundColor: options.rowBackgroundColor,
            parentId: r.parentId,
        };
        if (row.edits.length > this.maxEdits.length) this.maxEdits = row.edits;

        return row;
    }

    private isCheckboxVisible(r: any): boolean {
        return (this.options.select?.showCheckboxesWithEditPermission && this.hasEditPermission(false, r)) || this.showCheckboxes;
    }
    
    private updateLockedStatus(r: any, locked: boolean): boolean {
        if (this.options.select?.showCheckboxesWithEditPermission) {
            if (this.options.select?.isLocked) {
                locked = this.options.select.isLocked(r);
            }
            if (!this.hasEditPermission(false, r)) {
                locked = true;
            }
        }
        return locked;
    }

    getDummyEdits(editCount: number) {
        return new Array(Math.max(0, this.maxEdits.length - editCount)).fill(0);
    }

    private isRowSelected(key: string): boolean {
        return !!this.selectedKeys[key] || !!this.selectedNestedKeys[key];
    }

    private rowsUpdated() {
        this.setRows();
        this.applyFiltersAndSearch(true, false);
        this.debouncedEmitRows();
    }

    private resetCurRows() {
        this.curRows = this.allRows.slice(0, this.allRows.length);
    }

    private updateSlicedRows() {
        this.folderIds = this.curRows.map(r => r.key);
        this.slicedRows = this.curRows.slice(this.pageStart, this.pageEnd + 1);
        this.totalRows = this.curRows.filter(r => !r.isFolder).length;
        if (this.curDirection === PaginationDirection.desc) this.slicedRows = this.slicedRows.reverse().slice();
        this.options.pagination?.pageUpdated?.call(this, this.totalRows, this.totalItems ?? 0);
        this.updateFolderItemsCount();
    }

    private updateFolderItemsCount() {
        if (!this.folders || !this.allRows) return;
        const immediateCounts = _.countBy(this.allRows, r => r.original.folderId);
        const children = _.groupBy(this.folders, f => f.parentId);
        const getCount = _.memoize(id => (immediateCounts[id] ?? 0) + _.sumBy(children[id] ?? [], child => getCount(child.id)));
        this.folders.forEach(f => this.folderItemsCountMap[f.id] = getCount(f.id));
    }

    getItemCount(): number {
        return this.curRows.filter(r => !r.isFolder).length;
    }

    private async setTableOptions() {
        this.options.headerOptions = this.options.headerOptions || {};
        this.options.headerOptions.folderType = this.options.folderType;
        this.options.headerOptions.editPermissionAction = this.options.editPermissionAction;
        this.options.headerOptions.facilityId = this.options.facilityId;
        this.options.headerOptions.facilityIds = this.options.facilityIds;
        this.options.headerOptions.allFacilities = this.options.allFacilities;

        if (this.options.hasDefaultSort !== false) {
            this.prepareDefaultSort();
        }
        this.setHeaders();
        if (this.options.externalSearch) {
            if (this.options.externalSearch.fields && this.options.externalSearch.fields.length > 0) this.searchFields = this.options.externalSearch.fields;
        }
        if (this.options.select) {
            if (this.options.select.rowsDblClickable !== undefined) this.rowsDblClickable = this.options.select.rowsDblClickable;
            if (this.options.select.rowsClickable !== undefined) this.rowsClickable = this.options.select.rowsClickable;
            if (this.options.select.selectable !== undefined) this.selectable = this.options.select.selectable;
            if (this.options.select.multiSelect !== undefined) this.multiSelect = this.options.select.multiSelect;
            if (this.options.select.showCheckboxes !== undefined) this.showCheckboxes = this.options.select.showCheckboxes;
            if (this.options.select.checkOnClick !== undefined) this.checkOnClick = this.options.select.checkOnClick;
        }

        if (this.options.folderType) {
            this.folders = await this.folderService.getAll(this, this.options.folderType, this.options.facilityId);
            this.folders = this.folders.filter(x => this.hasViewPermission(true, x));
            this.folders.unshift({
                id: ROOT_FOLDER,
                name: this.translationService.getImmediate('table.folders.back'),
                parentId: 'none',
            });

            this.folderMap = _.keyBy(this.folders, f => f.id);
            // if any folders are orphaned, move them to the root level so they can be seen
            this.folders
                .filter(f => f.parentId !== 'none' && !this.folderMap[f.parentId])
                .forEach(f => f.parentId = null);

            if (!this.optionsLoaded) {
                this.folderId = _.get(this.route, 'snapshot.queryParams.folderId', null) || null;
                this.setFolderPath();
            }
            this.rowsUpdated();
        } else {
            this.folders = [];
            this.folderMap = {};
            this.folderId = null;
            this.setFolderPath();
            this.rowsUpdated();
        }

        this.inTableThatIsMoveable = this.options.folderType == null ? false : true;
        this.optionsLoaded = true;
    }

    private setHeaders() {
        this.colKey['folderBack'] = {
            title: 'table.folders.name',
            colKey: 'folderBack',
            type: RowItemType.folderBack,
        };
        this.colKey['folderName'] = {
            title: 'table.folders.name',
            colKey: 'folderName',
            type: RowItemType.text,
            prefix: (row) => {
                return { faIcon: 'fas fa-folder', tooltip: row.name };
            },
        };

        if (this.options.columnGroups?.length) {
            const order = this.options.columnGroups.flatMap(x => x.ids);
            this.options.columns = _.sortBy(this.options.columns, c => order.indexOf(c.id));
        }

        this.options.columns.forEach(head => {
            this.colKey[head.colKey] = head;
            if (head.sort) {
                head.sort = {
                    sortable: head.sort.sortable === false ? false : true,
                    selected: head.sort.selected === true ? true : false,
                    sortAsc: head.sort.sortAsc === true ? true : false,
                    fn: head.sort.fn,
                };
            } else {
                head.sort = {
                    sortable: true,
                    selected: false,
                    sortAsc: false,
                    fn: head.sort.fn,
                };
            }
        });
    }
    
    getHeaderGroupWidth(group: TableColumnGroup, addOffset = false) {
        let width = addOffset ? (this.options.columnAlignmentOffset ? this.options.columnAlignmentOffset - 5 : 0) : 0;
        for (const id of group.ids) {
            const column = this.elRef.nativeElement.querySelector(`#${id}`);
            if (column) {
                width += column.offsetWidth;
                const style = column.currentStyle || window.getComputedStyle(column);
                width += parseFloat(style.marginLeft) + parseFloat(style.marginRight);
            }
        }
        return width;
    }

    private async applyFiltersAndSearch(applySort: boolean = false, resetView: boolean = true, reloadData: boolean = false) {
        if (applySort) {
            await this.applySort(reloadData);
        }
        if (!this.options.paginate) {
            this.resetCurRows();
            this.filterRowsBasedOnTableFiltersAndFolderSelected();
            this.filterFolderRowsBasedOnPermissions();
            this.applySearch();
            this.dragDisabled = !this.hasEditPermission();
            if (resetView) {
                this.showMore(true);
            } else if (this.curRows.length <= this.initPageSize) {
                this.showMore(true);
            } else {
                this.updateSlicedRows();
            }
        } else {
            this.resetCurRows();
            this.updateSlicedRows();
        }

        this.cdr.markForCheck();
        this.renderEnoughForScroll();
    }

    private filterRowsBasedOnTableFiltersAndFolderSelected(): void {
        if (this.options.filters && this.options.filters.length > 0 && this.options.filters.find(f => f.selected === true)) {
            this.curRows = this.curRows.filter(r => !r.isFolder && this.options.filters.some(f => f.selected && f.filterFn(r)));
        } else if (!this.searchQuery) {
            if (this.tableService.searchFilter?.value) { // flatten list when searching via table header search
                this.curRows = this.curRows.filter(r => !r.isFolder);
            } else {
                this.curRows = this.curRows.filter(r => {
                    return r.isFolder && (r.key === ROOT_FOLDER && this.folderId || r.original.parentId === this.folderId
                            || r.key !== ROOT_FOLDER && !this.folderId && !this.folderMap[r.original.parentId])
                        || !r.isFolder && (r.original.folderId === this.folderId || !this.folderId && !this.folderMap[r.original.folderId]);
                });
            }
        }
    }

    private filterFolderRowsBasedOnPermissions(): void {
        this.curRows = this.curRows.filter(r => {
            return r.isFolder && (r.key === ROOT_FOLDER || this.hasViewPermission(true, r.original))
                || !r.isFolder && this.hasViewPermission(false, r.original);
        }).map(r => this.mapBackFolderButtonLabel(r));
    }

    private mapBackFolderButtonLabel(row: TableRow): TableRow {
        // Change back button label to show the current folder selected
        if (row.original.id === ROOT_FOLDER) {
            const folder = this.folderMap[this.folderId];
            if (folder) row.items = [{ colKey: 'folderBack', value: folder.name }];
        }
        return row;
    }

    private setExternalSearch(query: string): void {
        this.searchQuery = query;
        this.applyFiltersAndSearch();
    }

    private applySearch() {
        const getSearchValue = (type: RowItemType, item: RowItem) => {
            switch (type) {
                case RowItemType.avatarPerson:
                    return item.value.name;
                default:
                    return item.value;
            }
        };
        if (this.searchQuery?.length > 0 && this.curRows.length > 0 && this.searchFields.length > 0) {
            const searchParts = this.searchQuery.split(' ').map(p => p.toLowerCase());
            this.curRows = this.curRows.filter(r => !r.isFolder);
            this.curRows = this.curRows.filter(r => {
                return !r.isFolder && searchParts.every(search => {
                    return r.items.some(i => {
                        if (i.colKey === 'name' || this.searchFields.includes(i.colKey)) {
                            const colType = this.options.columns.find(c => c.colKey === i.colKey).type;
                            const value = getSearchValue(colType as RowItemType, i);
                            if (value && value.toString().toLowerCase().includes(search)) return true;
                            if (i.prefix && i.prefix.tooltip && i.prefix.tooltip.toString().toLowerCase().includes(search)) return true;
                        }
                        return false;
                    });
                });
            });
        }
    }

    private async applySort(reloadData: boolean = false) {
        this.headers = this.options.columns.filter(h => h.sort.selected === true);
        if (!this.headers.length) return;

        // Only request from the backend if the data order changes.
        if (this.options.paginate && (this.allRows.length !== this.totalItems) && reloadData) {
            const limit = this.pageEnd - this.pageStart + 1;
            this.curDirection = PaginationDirection.asc;
            await this.options.paginate.loadMore(limit, this.headers[0], this.curDirection, reloadData);
            this.resetCurRows();
            this.updateSlicedRows();
            this.slicedRows.sort((a, b) => {
                for (let i = 1; i < this.headers.length; i++) {
                    const cmp = this.compareFn(a, b, this.headers[i], this.headers[i].sort.sortAsc);
                    if (cmp !== 0) return cmp;
                }
                return 0;
            });
        } else {
            if (!this.options.paginate) {
                this.allRows.sort((a, b) => {
                    for (let i = 0; i < this.headers.length; i++) {
                        const cmp = this.compareFn(a, b, this.headers[i], this.headers[i].sort.sortAsc);
                        if (cmp !== 0) return cmp;
                    }
                    return 0;
                });
                this.allRows.sort((a, b) => a.isFolder && !b.isFolder ? -1 : !a.isFolder && b.isFolder ? 1 :
                    a.isFolder && b.isFolder ? a.key === ROOT_FOLDER ? -1 : b.key === ROOT_FOLDER ? 1 :
                    a.items[0].value.toLowerCase() < b.items[0].value.toLowerCase() ? -1 : 1 : 0);
            } else if (reloadData) {
                this.allRows.sort((a, b) => {
                    for (let i = 0; i < this.headers.length; i++) {
                        const cmp = this.compareFn(a, b, this.headers[i], this.headers[i].sort.sortAsc);
                        if (cmp !== 0) return cmp;
                    }
                    return 0;
                });
                this.allRows.sort((a, b) => a.isFolder && !b.isFolder ? -1 : !a.isFolder && b.isFolder ? 1 :
                    a.isFolder && b.isFolder ? a.key === ROOT_FOLDER ? -1 : b.key === ROOT_FOLDER ? 1 :
                    a.items[0].value.toLowerCase() < b.items[0].value.toLowerCase() ? -1 : 1 : 0);
            } else {
                if (this.curDirection === PaginationDirection.desc) {
                    this.allRows.sort((a, b) => {
                        for (let i = 0; i < this.headers.length; i++) {
                            const cmp = this.compareFn(a, b, this.headers[i], !this.headers[i].sort.sortAsc);
                            if (cmp !== 0) return cmp;
                        }
                        return 0;
                    });
                } else {
                    this.allRows.sort((a, b) => {
                        for (let i = 0; i < this.headers.length; i++) {
                            const cmp = this.compareFn(a, b, this.headers[i], this.headers[i].sort.sortAsc);
                            if (cmp !== 0) return cmp;
                        }
                        return 0;
                    });
                }

                this.allRows.sort((a, b) => a.isFolder && !b.isFolder ? -1 : !a.isFolder && b.isFolder ? 1 :
                    a.isFolder && b.isFolder ? a.key === ROOT_FOLDER ? -1 : b.key === ROOT_FOLDER ? 1 :
                    a.items[0].value.toLowerCase() < b.items[0].value.toLowerCase() ? -1 : 1 : 0);
            }
        }

        // Send focused row to top
        if (this.focusedRow) {
            const index = this.allRows.findIndex(row => row.key === this.focusedRow);
            if (index >= 0) {
                const focusedRow = this.allRows[index];
                this.allRows.splice(index, 1);
                this.allRows.unshift(focusedRow);
            }
        }
    }

    // eslint-disable-next-line complexity
    private compareFn(a: TableRow, b: TableRow, header: TableColumn, ascending: boolean): number {
        const riA = a.items.find(ri => ri.colKey === header.colKey);
        const riB = b.items.find(ri => ri.colKey === header.colKey);

        if ((riA === riB || riA?.value === riB?.value) && a.key && b.key)
            return ascending ? a.key.localeCompare(b.key, undefined, { sensitivity: 'base' }) : b.key.localeCompare(a.key, undefined, { sensitivity: 'base' });

        if (!riA || !riA.value) return ascending ? -1 : 1;
        if (!riB || !riB.value) return ascending ? 1 : -1;

        let aVal;
        let bVal;
        switch (header.type) {
            case RowItemType.text:
                aVal = String(riA.value).toLowerCase();
                bVal = String(riB.value).toLowerCase();
                break;
            case RowItemType.number:
                if (ascending) {
                    return String(riA.value).localeCompare(String(riB.value), undefined, { numeric: true, sensitivity: 'base' });
                } else {
                    return String(riB.value).localeCompare(String(riA.value), undefined, { numeric: true, sensitivity: 'base' });
                }
            case RowItemType.icon:
                aVal = riA.value.faIcon || riA.value.matIcon || riA.value.svgFile;
                bVal = riB.value.faIcon || riB.value.matIcon || riB.value.svgFile;
                break;
            case RowItemType.avatarPerson:
                aVal = String(riA.value?.name).toLowerCase();
                bVal = String(riB.value?.name).toLowerCase();
                break;
            case RowItemType.image:
                aVal = riA.value?.fallbackText.toLowerCase() || riA.value?.image.toLowerCase();
                bVal = riB.value?.fallbackText.toLowerCase() || riB.value?.image.toLowerCase();
                break;
            case RowItemType.duration:
                aVal = riA.value?.number;
                bVal = riB.value?.number;
                break;
            case RowItemType.date:
            default:
                aVal = riA.value;
                bVal = riB.value;
                break;
        }
        if (riA.sort) aVal = riA.sort;
        if (riB.sort) bVal = riB.sort;

        if (ascending) {
            return aVal < bVal ? -1 : aVal === bVal && a.key && b.key ? a.key.localeCompare(b.key, undefined, { sensitivity: 'base' }) : 1;
        } else {
            return aVal < bVal ? 1 : aVal === bVal && a.key && b.key ? b.key.localeCompare(a.key, undefined, { sensitivity: 'base' }) : -1;
        }
    }

    selectRow(row: TableRow) {
        if (!this.selectable) return;

        if (row.isFolder) {

            if (row.selected) {
                row.selected = false;
                delete this.selectedFolderKeys[row.key];
                this.toggleNestedKeysSelection({ folderId: row.key, select: false });
            } else {
                if (this.multiSelect) {
                    row.selected = true;
                    this.selectedFolderKeys[row.key] = row;
                } else {
                    Object.keys(this.selectedFolderKeys).forEach(key => {
                        this.selectedFolderKeys[key].selected = false;
                        delete this.selectedFolderKeys[key];
                        this.toggleNestedKeysSelection({ folderId: key, select: false });
                    });
                    row.selected = true;
                    this.selectedFolderKeys[row.key] = row;
                }
                this.toggleNestedKeysSelection({ folderId: row.key, select: true });
            }

        } else {

            if (row.selected) {
                row.selected = false;
                delete this.selectedKeys[row.key];
                delete this.selectedNestedKeys[row.key];
            } else {
                if (this.multiSelect) {
                    row.selected = true;
                    const folderId = row.original.folderId ?? null;
                    if (folderId === this.folderId) {
                        this.selectedKeys[row.key] = row;
                    } else {
                        this.selectedNestedKeys[row.key] = row;
                    }
                } else {
                    Object.keys(this.selectedKeys).forEach(key => {
                        this.selectedKeys[key].selected = false;
                        delete this.selectedKeys[key];
                    });
                    row.selected = true;
                    this.selectedKeys[row.key] = row;
                }
            }
        }
        this.debouncedEmitRows();
        this.cdr.markForCheck();
    }

    highlightRow(row: TableRow) {
        this.allRows.forEach(r => r.highlighted = false);
        if (row) row.highlighted = true;
    }

    async rowClick(row: TableRow, dblClick: boolean = false) {
        if (row.isFolder) {
            if (row.key === ROOT_FOLDER) {
                return this.selectFolder(this.getPrevious());
            } else {
                return this.selectFolder(row.key);
            }
        }
        if (dblClick) {
            this.isRowDblClick = true;
        } else {
            this.isRowDblClick = false;
            await sleep(250);
            if (!this.isRowDblClick) {
                if (this.checkOnClick) {
                    this.selectRow(row);
                }
                if (this.hightlightOnClick) {
                    this.highlightRow(row);
                }
            }

            this.rowClickOutput.emit(row);
        }
    }

    columnClick(item: RowItem, row: TableRow) {
        this.columnClickOutput.emit({ row, item });
    }

    tableEditClick(edit: TableEdit): void {
        this.tableEditClickOutput.emit({ edit });
    }

    colPrefixClick(item: RowItem, row: TableRow): void {
        this.colPrefixClickOutput.emit({ row, item });
    }

    colPostfixClick(item: RowItem, row: TableRow): void {
        this.colPostfixClickOutput.emit({ row, item });
    }

    colEditClick(edit: RowEdit, row: TableRow): void {
        this.colEditClickOutput.emit({ row, edit });
    }

    canMove(row: TableRow) {
        return !row.locked
            && row.key !== ROOT_FOLDER
            && !SYSTEM_FOLDER_TYPES.includes(row.key)
            && this.options.folderType
            && this.hasEditPermission(row.isFolder, row.original);
    }

    getRowChildren(row: TableRow): TableRow[] {
        if (!row.key) return [];
        return this.slicedRows.filter(r => r.parentId === row.key);
    }

    selectFolder(key: string) {
        this.selectedKeys = {};
        this.selectedFolderKeys = {};
        this.selectedNestedKeys = {};
        this.curRows.forEach(r => r.selected = false);
        this.emitRows();
        this.rowsUpdated();
        if (key === ROOT_FOLDER) {
            this.folderId = null;
        } else {
            this.folderId = key;
        }
        this.folderIdOutput.emit(this.folderId);

        this.setFolderPath();
        this.applyFiltersAndSearch();
    }

    getPrevious(): string {
        let folderId = this.folders.find(f => f.id === this.folderId)?.parentId;
        if (!this.folderMap[folderId]) folderId = ROOT_FOLDER;
        return folderId;
    }

    setFolderPath() {
        this.folderPath = FolderService.getPath(this.folders, this.folderId);
        this.folderPath = FolderService.getFolderPathDisplay(this.folderPath);
    }

    handleOverrideSelection(item: RowItem, row: TableRow): void {
        this.unselectAll();
        this.curRows.forEach(r => {
            if (row.original?.id === r.original?.id) {
                if (!r.isFolder) {
                    r.selected = true;
                    this.selectedKeys[r.key] = r;
                } else {
                    if (r.key !== ROOT_FOLDER && !SYSTEM_FOLDER_TYPES.includes(r.key) && !r.locked) {
                        r.selected = true;
                        this.selectedFolderKeys[r.key] = r;
                    }
                }
            }
        });
    }

    toggleNestedKeysSelection(options: {folderId: string, select: boolean}) {
        const items = this.allRows.filter(r => r.original.folderId === options.folderId || r.original.parentId === options.folderId);
        items.forEach(x => {
            if (x.isFolder) {
                this.toggleNestedKeysSelection({ folderId: x.key, select: options.select});
            } else {
                if (options.select) {
                    x.selected = true;
                    this.selectedNestedKeys[x.key] = x;
                } else {
                    x.selected = false;
                    delete this.selectedNestedKeys[x.key];
                }
            }
        });
    }

    selectAll() {
        if (this.areAllSelected()) {
            this.selectedKeys = {};
            this.selectedFolderKeys = {};
            this.selectedNestedKeys = {};
            this.curRows.forEach(r => r.selected = false);
        } else {
            this.getAllSelectable().forEach(r => {
                if (r.isFolder) {
                    r.selected = true;
                    this.selectedFolderKeys[r.key] = r;
                    this.toggleNestedKeysSelection({ folderId: r.key, select: true });
                }
                else {
                    r.selected = true;
                    this.selectedKeys[r.key] = r;
                }
            });
        }
        this.debouncedEmitRows();
    }

    unselectAll() {
        this.selectedKeys = {};
        this.selectedFolderKeys = {};
        this.selectedNestedKeys = {};
        this.curRows.forEach(r => r.selected = false);
        this.debouncedEmitRows();
    }

    areAllSelected() {
        if (this.tableService?.searchFilter?.value?.length) return this.getSelectedAndNestedKeyCount() === this.getAllSelectable().length;
        return this.getSelectedKeyCount() >= this.getAllSelectable().length && this.getSelectedKeyCount() > 0;
    }

    getSelectedAndNestedKeyCount() {
        return Object.keys(this.selectedKeys).length + Object.keys(this.selectedNestedKeys).length;
    }

    getAllSelectable() {
        return this.curRows.filter(r => 
            !r.locked && r.checkbox &&
            (!r.isFolder || (r.key !== ROOT_FOLDER && !SYSTEM_FOLDER_TYPES.includes(r.key))),
        );
    }
    
    showMore(useStart: boolean) {
        if (this.noMoreItems) {
            this.updateSlicedRows();
            return;
        }
        this.loadingMore = true;
        this.pageEnd = useStart
            ? Math.min(this.pageStart + (this.initPageSize - 1), this.curRows.length - 1)
            : Math.min(this.pageEnd + this.showMoreSize, this.curRows.length - 1);
        this.updateSlicedRows();
        this.loadingMore = false;
    }

    debouncedShowMore = _.debounce(this.showMore, 500, { leading: true });

    handleScroll(event: Event) {
        if ((<HTMLElement>event.target).scrollTop > this.BACK_TO_TOP_DISTANCE) {
            this.showBackToTop = true;
        } else {
            this.showBackToTop = false;
        }
        if (this.loadingMore) return;
        const nativeScroll = this.tableBody.elementRef.nativeElement;
        if (nativeScroll.scrollTop > nativeScroll.scrollHeight - nativeScroll.clientHeight - 100) {
            this.debouncedShowMore(false);
        }
    }

    handleHorizontalScroll(event: WheelEvent | null) {
        if (this.options.columnGroups?.length && (!event || event.deltaX !== 0)) this.repositionColumnGroupHeaders();
    }

    private repositionColumnGroupHeaders() {
        const tableContainer = this.elRef.nativeElement.querySelector(`.folder-tree-table-container`);
        if (!tableContainer) return;
        for (let i = 0; i < this.options.columnGroups?.length; i++) {
            const column = tableContainer.querySelector(`#col-group-${i}`);
            if (!column) return;
            const label = column.querySelector(`.table-header-group-item-label`);
            if (!label) break;

            const tableContainerRect = tableContainer.getBoundingClientRect();
            const columnRect = column.getBoundingClientRect();
            const labelRect = label.getBoundingClientRect();

            const containerX = tableContainerRect.x;
            const containerY = tableContainerRect.x + tableContainerRect.width;
            const columnX = columnRect.x;
            const columnY = columnRect.x + columnRect.width;

            let offset = 0, remainingWidth = 0;
            if (columnX < containerX) {
                offset = tableContainerRect.x - columnRect.x;
                if (columnY > containerY) remainingWidth = tableContainerRect.width;
                else remainingWidth = columnRect.width - offset;
            } else if (columnX > containerX) {
                offset = 0;
                if (columnY > containerY) remainingWidth = containerY - columnX;
                else remainingWidth = columnRect.width;
            }
            
            if (remainingWidth > (labelRect.width + 30)) {
                this.renderer.setStyle(label, 'transform', 'none');
                this.renderer.setStyle(label, 'left', `${offset + (remainingWidth / 2) - (labelRect.width / 2)}px`);
            }
        }
    }

    backToTop() {
        if (this.tableBody) this.tableBody.elementRef.nativeElement.scrollTop = 0;
    }

    handleAnchorClick(event: Event, row: TableRow) {
        event.preventDefault();
        event.stopPropagation();

        this.rowClick(row, false);
    }

    rowDragged(event: CdkDragMove) {
        this.dragPosition = event.pointerPosition;
        this.repositionDragPreviewContainer();
    }

    private repositionDragPreviewContainer(): void {
        const preview = this.elRef.nativeElement.querySelector('.cdk-drag.cdk-drag-preview');
        if (preview) {
            const dragContainer = preview.parentElement;
            const leftOffset = dragContainer?.getBoundingClientRect()?.x ?? 0;
            const topOffset = dragContainer?.getBoundingClientRect()?.y ?? 0;
            preview.style.transition = 'none';
            preview.style.left = `${ leftOffset * -1 }px`;
            preview.style.top = `${ topOffset * -1 }px`;
        }
    }

    async rowDropped(event: CdkDragDrop<any>) {
        let dragIndex = -1;
        if (this.dragContainer) {
            [...this.dragContainer.nativeElement.children].forEach((node, i) => {
                if (!node?.getBoundingClientRect) return;

                const rect = node.getBoundingClientRect();
                if (this.dragPosition.x >= rect.left && this.dragPosition.x <= rect.right
                    && this.dragPosition.y >= rect.top && this.dragPosition.y <= rect.bottom) {
                    dragIndex = i;
                }
            });
        }

        const move = event.item.data;
        const to = this.slicedRows[dragIndex];

        if (!to || move.key === to.key || move.key === ROOT_FOLDER || (event.distance.y < 10 && event.distance.y > -10)) return;

        if (to.isFolder) {

            const folder = to.key === ROOT_FOLDER ? (this.folders.find(f => f.id === this.folderId) || {}).parentId || null : to.key;
            if (move.isFolder) {
                await this.changeParent(move, folder);
            } else {
                this.rowMovedOutput.emit({ key: move.key, folderId: folder, success: (key) => {
                    this.allRows.forEach(r => {
                        if (r.key === move.key) {
                            r.original.folderId = folder;
                        }
                    });
                    this.applyFiltersAndSearch();
                } });
            }
        }
    }

    getRowLink(row: TableRow) {
        if (row.isFolder && this.options.folderIsLink === false) return null;
        return this.rowsClickable && row.clickable ? row.link : null;
    }

    getRouteParams(row: TableRow): {[key: string]: string} {
        if (row.isFolder) {
            if (this.options.folderIsLink === false) return null;
            if (row.key === ROOT_FOLDER) {
                const folderId = this.getPrevious();
                if (folderId === ROOT_FOLDER) {
                    return {};
                } else {
                    return { folderId };
                }
            } else {
                return { folderId: row.key };
            }
        }
        return { ...this.currRouteParams, ...this.getFolderParameter() };
    }

    getFolderParameter() {
        return this.folderId ? { folderId: this.folderId } : {};
    }

    hasViewPermission(isFolder: boolean, row: any) {
        if (isFolder) return this.hasFolderPermission(this.options.viewPermissionAction, row);
        return this.hasPermission(this.options.viewPermissionAction, row);
    }

    hasEditPermission(isFolder?: boolean, row?: any) {
        if (!row) return this.hasAddPermission();
        if (isFolder) return this.hasFolderPermission(this.options.editPermissionAction, row);
        return this.hasPermission(this.options.editPermissionAction, row);
    }

    private hasAddPermission() {
        return this.profileService.hasAddPermission(this.options.editPermissionAction, this.options.facilityId, !!this.options.facilityIds, this.folderId);
    }

    private hasFolderPermission(action: PermissionAction, row: any) {
        if (!action) return true;
        const isView = action === this.options.viewPermissionAction;
        return isView ? row.hasViewAccess : row.hasEditAccess;
    }

    private hasPermission(action: PermissionAction, row: any) {
        if (this.options.viewAny && action === this.options.viewPermissionAction) return true;
        if (this.options.hasPermission) {
            return this.options.hasPermission(action, row);
        }
        const facilityId = this.options.facilityId ?? (this.options.facilityIds ? this.options.facilityIds(row) : null);
        return this.profileService.hasFacilitiesPermission(action, facilityId, row.folderId, this.options.allFacilities ? this.options.allFacilities(row) : null);    
    }

    getPermissionTooltip(tip: string, row?: TableRow) {
        const permission = this.hasEditPermission(row?.isFolder, row?.original);
        if (!permission) {
            return this.translationService.getImmediate('generics.forbiddenAction', { action: tip }, true);
        }
        return this.translationService.getImmediate(tip);
    }

    trackByKey: TrackByFunction<TableRow> = (_, row: TableRow) => row && row.key;

    getTableSettings() {
        return JSON.parse(localStorage.getItem('table-settings') || '{}');
    }

    getTableSetting(setting: TableSetting) {
        const settings = this.getTableSettings();
        return _.get(settings, [this.options.title?.string, setting]);
    }

    onBack() {
        if (typeof this.options.back === 'function') {
            this.options.back();
        } else {
            this.location.back();
        }
    }

    async onChangePage(paginator: Paginator) {
        if (paginator.currentPage === this.curPage) {
            // eslint-disable-next-line no-console
            console.log('Duplicate Call');
            return;
        }

        let sort = paginator.direction;
        if (this.headers[0].sort.sortAsc) {
            sort = (sort === PaginationDirection.asc) ? PaginationDirection.desc : PaginationDirection.asc;
        }

        if (paginator.currentPage === paginator.totalPages && paginator.changedDirection) {
            sort = PaginationDirection.asc;
            this.allRows = [];
        }

        // if (paginator.currentPage === 1 && this.curPage !== 2) {
        if (paginator.currentPage === 1 && paginator.changedDirection) {
            sort = PaginationDirection.desc;
            this.allRows = [];
        }
        // Should be the default page size in all instances but the last page.
        const limit = paginator.endIndex - paginator.startIndex + 1;

        if (paginator.direction === PaginationDirection.desc) {
            this.pageEnd = paginator.totalItems - paginator.startIndex - 1;
            this.pageStart = paginator.totalItems - paginator.endIndex - 1;
        } else {
            // If paging from the left, copy index.
            this.pageStart = paginator.startIndex;
            this.pageEnd = paginator.endIndex;
        }

        if (this.allRows.length < paginator.endIndex || this.allRows.length < this.pageEnd) {
            await this.options.paginate.loadMore(limit, (this.headers.length > 0) ? this.headers[0] : null, sort, paginator.changedDirection);
        } else {
            this.setRows();
            this.applySort();
            this.resetCurRows();
            this.updateSlicedRows();
        }

        this.curPage = paginator.currentPage;
        this.curDirection = sort;
    }

    async handleEditColClick(edit: RowEdit, row: TableRow) {
        if (edit.disabled) return;
        if (edit.show && !edit.show(row.original)) return;

        if (edit.action === RowEditAction.delete) {
            try {
                const modalResult = await this.modalService.confirmDelete(this.options.deleteMessageKey);
                if (modalResult.action !== ModalActionType.submit) {
                    return;
                }
            } catch (err) { /*Ignore*/}
            if (row.isFolder) {
                try {
                    const results = await this.folderService.delete(this, this.options.folderType, row.key, this.options.facilityId);
                    this.allRows = this.allRows.filter(r => !results.ids.includes(r.key) && !results.folders.includes(r.key));
                    this.folders = this.folders.filter(r => !results.folders.includes(r.id));

                    _.remove(this.rows, r => results.ids.includes(r.id));

                    results.folders.forEach(folderKey => {
                        delete this.folderMap[folderKey];
                        this.folderChanges.emit({ folder: { id: folderKey }, action: FormAction.delete });
                    });

                    this.applyFiltersAndSearch();

                    AnalyticsService.track(StObject.Folder, StAction.Deleted, this.constructor.name);
                    return;
                } catch (e) {
                    return this.alertsService.sendError({ error: e, messageKey: 'ERRORS.FOLDERS.DELETE-FAILED' });
                }
            }
        } else if (edit.action === RowEditAction.edit && row.isFolder) {
            this.selectedFolderId = row.original.id;
            this.folderEditorOpen = true;
        } else if (edit.action === RowEditAction.move) {
            this.chooseFolder(row);
        }

        this.rowEditOutput.emit({ key: row.key, action: edit.action });
        AnalyticsService.track(StObject.TableRowAction, StAction.Clicked, this.constructor.name, { type: edit.action });
    }

    async handleReportExport() {
        const getFolderUrl = (folder: Folder) => (folder?.parentId ? `${getFolderUrl(this.folders.find(f => f.id === folder.parentId))}/` : '') + folder?.name;
        const exportData: TableExportData = {
            keys: Object.keys(this.selectedKeys),
            folderUrls: _.transform(this.folders, (map, folder) => map[folder.id] = getFolderUrl(folder), {}),
        };

        if (this.getSelectedKeyCount()) {
            const getAllSelectedFolders = (folders: Folder[]) => folders.flatMap(f => [f, ...getAllSelectedFolders(this.folders.filter(x => x.parentId === f.id))]);
            exportData.folderKeys = getAllSelectedFolders(this.folders.filter(f => f.id in this.selectedFolderKeys)).map(f => f.id);
        }
        
        this.exportReportOutput.emit(exportData);
    }

    async handleBulkEdit(bEdit: BulkEdit) {
        const errors: HttpError[] = [];
        if (bEdit.action === BulkEditAction.delete) {
            const modalResult = await this.modalService.confirmDelete(bEdit.message ?? 'generics.deleteFolderConfirm');
            if (modalResult.action !== ModalActionType.submit) {
                return;
            }

            const folders: BatchRequest[] = Object.keys(this.selectedFolderKeys).map(key => {
                return {
                    path: this.folderService.url(this.options.facilityId, this.options.folderType, key),
                    method: BatchMethod.DELETE,
                };
            });

            try {
                this.alertsService.setAppLoading(true);
                const response = await this.batchService.bulkDelete(
                    this,
                    [...Object.keys(this.selectedKeys), ...Object.keys(this.selectedNestedKeys)],
                    folders,
                    this.options.bulk.url,
                    this.options.bulk.cache,
                );

                this.allRows = this.allRows.filter(r => { r.selected = false; return !response.ids.includes(r.key) && !response.folders.includes(r.key); });
                if (this.folders) {
                    this.folders = this.folders.filter(r => !response.folders.includes(r.id));
                }
                _.remove(this.rows, r => response.ids.includes(r.id));

                response.folders.forEach(folderKey => {
                    delete this.folderMap[folderKey];
                    this.folderChanges.emit({ folder: { id: folderKey }, action: FormAction.delete });
                });

                if (response.errors.length > 0) {
                    response.errors.forEach(x => console.error(x));
                    errors.push(...response.errors);
                    if (errors.some(e => e.data.details.reason === 'people-assigned')) {
                        this.alertsService.sendError({ messageKey: 'ERRORS.FACILITY.PERMISSION.PEOPLE-ASSIGNED' });
                    } else {
                        this.alertsService.sendError({ messageKey: 'ERRORS.TABLE.BULK-DELETE' });
                    }
                }

                this.selectedFolderKeys = {};
                this.selectedNestedKeys = {};
                this.selectedKeys = {};

                this.applyFiltersAndSearch();
            } catch (e) {
                this.alertsService.sendError({ error: e, messageKey: 'ERRORS.TABLE.BULK-DELETE' });
            } finally {
                this.alertsService.setAppLoading(false);
            }
        }

        this.bulkEditOutput.emit({
            keys: Object.keys(this.selectedKeys),
            folders: Object.keys(this.selectedFolderKeys),
            nestedKeys: Object.keys(this.selectedNestedKeys),
            action: bEdit.action,
            errors,
        });
        AnalyticsService.track(StObject.TableBulkAction, StAction.Clicked, this.constructor.name, { type: bEdit.action });
    }
    
    private getChildrenFolders(folderId: string): string[] {
        const children = [];
        this.folders.forEach(f => {
            if (f.parentId === folderId) {
                children.push(f.id);
                children.push(...this.getChildrenFolders(f.id));
            }
        });
        return children;
    }

    getCircularFolderIds(): string[] {
        const returning = [];
        Object.keys(this.selectedFolderKeys).forEach(x =>{
            returning.push(x);
            returning.push(...this.getChildrenFolders(x));
        });
        return returning;
    }

    chooseFolder(rows: TableRow[] | TableRow) {
        this.activeRow = Array.isArray(rows) ? null : rows;
        this.currentFolderId = this.folderId;
        this.toggleModal(true, 550);
        if (!this.modalInput.header) this.modalInput.header = {};
        this.modalInput.header.textKey = !Array.isArray(rows) && rows && rows.isFolder ? 'table.move-folder' : 'table.move-item';
        this.circularFolderIds = this.getCircularFolderIds();
        this.selectableFolderIds = this.getSelectableFolderIds(Array.isArray(rows) ? rows : [rows]);
        this.activeModalType = ModalType.parent;
    }

    private getSelectableFolderIds(rows: TableRow[]) {
        const canMoveRowToFolder = (v: TableRow, folderId: string) => {
            const foldered = v.isFolder ? { ...v.original, parentId: folderId } : { ...v.original, folderId };
            return this.hasEditPermission(v.isFolder, foldered);
        };
        const folderIds = this.folders.filter(f => rows.every(v => canMoveRowToFolder(v, f.id))).map(f => f.id);
        if (rows.every(v => canMoveRowToFolder(v, null))) {
            folderIds.push(''); // the '- None -' folder
        }
        return folderIds;
    }

    toggleModal(isOpen: boolean, height: number = 250, width: number = 700, type: ModalType = null): void {
        if (!isOpen) {
            this.activeModalType = null;
            this.modalInput = null;
            return;
        }
        this.modalInput = { isOpen, height, width };
        this.activeModalType = ModalType.folder;
    }

    async changeParent(row: TableRow, parentId: string) {
        if (row && row.isFolder) {
            try {
                this.alertsService.setAppLoading(true);
                await this.folderService.update(this, this.options.folderType, { id: row.key, parentId }, this.options.facilityId);
                this.folderChanges.emit({ action: FormAction.delete, folder: { id: row.key } });
                this.folderChanges.emit({ action: FormAction.add, folder: _.merge({ ...row.original }, { parentId }) });

                this.folders.forEach(f => {
                    if (f.id === row.key) f.parentId = parentId;
                });
                this.applyFiltersAndSearch();
                AnalyticsService.track(StObject.Folder, StAction.Moved, this.constructor.name);
            } catch (err) {
                const messageKey = err.error && err.error.details && err.error.details.field === 'name' && err.error.details.reason === 'duplicate'
                    ? 'ERRORS.FOLDERS.DUPLICATE' : 'ERRORS.FOLDERS.UPDATE-FAILED';
                return this.alertsService.sendError({ error: err, messageKey });
            } finally {
                this.alertsService.setAppLoading(false);
            }
        } else {

            const keys = row ? [row.key] : Object.keys(this.selectedKeys);
            const folders: BatchRequest[] = Object.keys(this.selectedFolderKeys).map(key => {
                return {
                    path: this.folderService.url(this.options.facilityId, this.options.folderType, key),
                    method: BatchMethod.PUT,
                    post: { parentId },
                };
            });

            const errors: HttpError[] = [];
            try {
                this.alertsService.setAppLoading(true);
                // Folders should still be handled even if customAction is set, which makes this kind of ugly 🤷‍♂️
                const results = await this.batchService.bulkMove(this,
                                                                    !this.options.bulk.customAction ? keys : [],
                                                                    folders,
                                                                    parentId,
                                                                    !this.options.bulk.customAction ? this.options.bulk.url : () => { return ''; }, 
                                                                    this.options.bulk.cache);
                AnalyticsService.track(StObject.Bulk, StAction.Moved, this.constructor.name);

                this.allRows.forEach(r => {
                    if (results.ids.includes(r.key)) {
                        r.original.folderId = parentId;
                    } else if (results.folders.includes(r.key)) {
                        r.original.parentId = parentId;
                        this.folderService.setItemFolderMap(this.options.folderType, this.options.facilityId, r.original);
                        this.folderChanges.emit({ action: FormAction.delete, folder: { id: r.key } });
                        this.folderChanges.emit({ action: FormAction.add, folder: _.merge({ ...r.original }, { parentId }) });
                    }
                    r.selected = false;
                    r.selected = false;
                });

                this.selectedKeys = {};
                this.selectedFolderKeys = {};
                this.selectedNestedKeys = {};
                this.applyFiltersAndSearch();

                if (results.errors.length > 0) {
                    results.errors.forEach(x => console.error(x));
                    errors.push(...results.errors);
                    this.alertsService.sendError({ messageKey: 'ERRORS.TABLE.BULK-MOVE' });
                }

            } catch (e) {
                this.alertsService.sendError({ error: e, messageKey: 'ERRORS.TABLE.BULK-MOVE' });
            } finally {
                if (this.options.bulk.customAction) {
                    this.bulkEditOutput.emit({
                        keys: keys,
                        folders: Object.keys(this.selectedFolderKeys),
                        nestedKeys: Object.keys(this.selectedNestedKeys),
                        action: BulkEditAction.move,
                        parentFolderId: parentId,
                        errors,
                    });
                }
                this.alertsService.setAppLoading(false);
            }
        }
        this.toggleModal(false);
    }

    public async handleFolderEditorModalClose(action: ModalActionType) {
        this.selectedFolderId = null;
        this.folderEditorOpen = false;
        this.folderEditorModalOutput.emit(action);
    }

    addRow() {
        this.options.headerOptions?.actions?.[0]?.clickAction();
    }
}
